<template>
	<div class="Site PageBox" style="margin:0 auto">
		<van-nav-bar
				fixed
				:border="false"
				:title="$t('search[0]')"
				left-arrow
				@click-left="$router.go(-1)"
		/>
		<div class="searchPanel" v-for="(items,key) in list" :key="key">
			<div style="padding: 10px">{{$t('searchs[\"'+key+'\"]')}}:</div>
			<div class="searchItem" v-for="(item,index) in items" :key="index" >
				{{item['order_number']}}
			</div>
			<div class="searchItem" v-if="items.length==0" style="text-align: center">
				{{$t('search[1]')}}
			</div>
		</div>

	</div>
</template>
<script>
	import '@/assets/css/test.css';
	import platform from '@/share/js/platform/platform'
	import digg from '@/share/js/platform/digg'
	import facebook from '@/share/js/platform/facebook'
	import tumblr from '@/share/js/platform/tumblr'
	import reddit from '@/share/js/platform/reddit'
	import pinterest from '@/share/js/platform/pinterest'
	import googlePlus from '@/share/js/platform/googlePlus'
	import twitter from '@/share/js/platform/twitter'
	import whatsapp from '@/share/js/platform/whatsapp'
	import twitterFollow from '@/share/js/platform/twitterFollow'
	import sharrre from '@/share/js/jquery.sharrre'
	import $ from "jquery";
	import $Dialog from "../common/Dialog";
	export default {
		name: 'Login',
		components: {

		},
		props: ["word"],
		data() {
			return {
				list:[],
			}
		},
		computed: {},
		watch: {

		},
		created() {

		},
		mounted() {

			this.$Model.GetSearch({word:this.word}, data => {
				this.isLoad = false;
				if (data.code == 1) {
					this.list = data.data;
				}else{
					$Dialog.Toast(data.code_dec);
				}
			});
		},
		activated() {

		},
		destroyed() {

		},
		methods: {
		}
	}
</script>
<style scoped>
	.PageBox {
		color: #8AC0C0;
		background-color: #0F4F4E;
	}
	.PageBox>>>.van-nav-bar{
		background: #0F4F4E;
	}
	.van-nav-bar>>>.van-nav-bar__title{
		color:#FFFEFF;
	}
	.van-nav-bar>>>.van-nav-bar__arrow {
		color:#FFFEFF;
	}
	.searchPanel{
		margin: 0px auto;
		width: 90%;
		margin-bottom: 30px;
	}
	.searchItem{
		background: #337F7B;
		border-radius: 5px;
		padding: 10px;
		color:#e7e7e7;
		margin-bottom: 10px;
	}
</style>
